<template>
    <div class='contactContainer'>
      <div class='container1080-con'>
        <div class='title'>留下您的联系方式</div>
        <div class='subTitle'>晶运东来专属顾问会尽快联系您</div>
        <div class='form'>
          <a-input class='input' v-model='name' type="text" placeholder="称呼" style='margin-right:2%'></a-input>
          <a-input  class='input' v-model='phone' type="text"  placeholder="手机号（必填）"></a-input>
          <a-input  class='input' v-model='qq' type="text"  placeholder="微信/QQ" style='margin-right:2%'></a-input>
          <div class='input' style='text-align:left'>
          <span  style='margin-right:20px;color:#ccc'>已有网站</span> 
            <template>
              <a-radio-group name="radioGroup">
                <a-radio :value="1">
                  是
                </a-radio>
                <a-radio :value="2">
                  否
                </a-radio>
              </a-radio-group>  
            </template>
          </div>
        </div>
    <div class='btn' style='width:280px;margin-left: calc(50% - 140px)'  @click='submit'>预约营销顾问 </div>
    <div class='help'>我们随时为您提供帮助</div>
    <div class='phone'>400-666-3288</div>
  </div>
  </div>
</template>

<script>
export default {
 name: 'ContactUs',
 components:{
   
 },
  data() {
    return {
      name:'',
      phone:'',
      qq:''
    };
  },
    methods:{
    submit(){
      if(!this.phone){
        this.$message.error('请填写手机号');
      }
      else{
        this.$message.success('感谢您的预约');
      }
    }
  }
};
</script>

<style>
 .container1080-con{
  width: 1080px;
  margin: auto;
  padding: 0!important;
}
.contactContainer{
  height: 580px;
  background-color: #f9fcff;
  padding-top:40px ;
}
.contactContainer .title{
  font-size: 32px;
    color: #181818;
    font-weight: 400;
    margin-left: 15px;
}
.contactContainer .subTitle{
      color: #606060;
    font-size: 16px;
    margin-top: 20px;
    margin-left: 15px;
    margin-bottom: 40px;

}
.form{
  width: 80%;
  margin: 0 auto;
}
.form .input{
  width: 45%;
  border-radius: 50px!important;
    height: 48px!important;
    line-height: 48px!important;
    margin-bottom: 40px!important;
    padding: 0 20px!important;
    border: 1px solid #dbe5ed!important;
    display: inline-block;
}
.help{
    font-size: 16px;
    font-weight: 400;
    color: #222;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: 30px;
}
.phone{
    font-size: 32px;
    color: #2f6aff;
    line-height: 44px;
    position: relative;
    left: 8px;
    top: 2px;
    letter-spacing: -1px;
    font-weight: bold;
}


</style>